<template>
  <section id="logo">
    <div class="wrapper">
      <div class="title-area">
        <h1>Logo</h1>
        <h2 class="mb-6">
          Sélectionnez les fichiers que vous souhaitez télécharger
        </h2>
      </div>
      <div class="elements-area columns">
        <div class="column is-4">
          <h4>Normal</h4>
          <div class="form-group">
            <input
              type="checkbox"
              id="and-logo-1"
              value="AND-logo"
              v-model="logoName"
              class="invisible"
            />
            <label for="and-logo-1" class="box checkbox"
              ><img src="/images/AND-logo.png" alt="Anderson Logo normal" />
            </label>
          </div>
          <div class="form-group">
            <input
              type="checkbox"
              id="and-logo-2"
              value="AND-logo-square"
              v-model="logoName"
              class="invisible"
            />
            <label for="and-logo-2" class="box checkbox"
              ><img
                src="/images/AND-logo-square.png"
                alt="Anderson Logo normal"
              />
            </label>
          </div>
        </div>
        <div class="column is-4">
          <h4>Noir</h4>
          <div class="form-group">
            <input
              type="checkbox"
              id="and-logo-3"
              value="AND-logo-black"
              v-model="logoName"
              class="invisible"
            />
            <label for="and-logo-3" class="box checkbox"
              ><img
                src="/images/AND-logo-black.png"
                alt="Anderson Logo normal"
              />
            </label>
          </div>
          <div class="form-group">
            <input
              type="checkbox"
              id="and-logo-4"
              value="AND-logo-square-black"
              v-model="logoName"
              class="invisible"
            />
            <label for="and-logo-4" class="box checkbox"
              ><img
                src="/images/AND-logo-square-black.png"
                alt="Anderson Logo normal"
              />
            </label>
          </div>
        </div>
        <div class="column is-4">
          <h4>Blanc</h4>
          <div class="form-group">
            <input
              type="checkbox"
              id="and-logo-5"
              value="AND-logo-white"
              v-model="logoName"
              class="invisible"
            />
            <label for="and-logo-5" class="box checkbox"
              ><img
                src="/images/AND-logo-white.png"
                alt="Anderson Logo normal"
              />
            </label>
          </div>
          <div class="form-group">
            <input
              type="checkbox"
              id="and-logo-6"
              value="AND-logo-square-white"
              v-model="logoName"
              class="invisible"
            />
            <label for="and-logo-6" class="box checkbox"
              ><img
                src="/images/AND-logo-square-white.png"
                alt="Anderson Logo normal"
              />
            </label>
          </div>
        </div>
      </div>
      <div class="extension-area columns">
        <div class="column is-12">
          <h4>Extension</h4>

          <div class="columns">
            <div class="form-group column is-2">
              <input
                type="radio"
                id="png"
                name="ext"
                value=".png"
                v-model="logoExt"
                class="invisible"
              />
              <label for="png" class="box radio"> PNG </label>
            </div>
            <div class="form-group column is-2">
              <input
                type="radio"
                id="jpg"
                name="ext"
                value=".jpg"
                v-model="logoExt"
                class="invisible"
              />
              <label for="jpg" class="box radio"> JPG </label>
            </div>
            <div class="form-group column is-2">
              <input
                type="radio"
                id="svg"
                name="ext"
                value=".svg"
                v-model="logoExt"
                class="invisible"
              />
              <label for="svg" class="box radio"> SVG </label>
            </div>
            <button
              v-if="!isEmptyName && !isEmptyExt"
              class="button submit primary is-large"
              @click="onClickDownload()"
            >
              Télécharger
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { saveAs } from "file-saver";
import JSZip from "jszip";
export default {
  name: "Logo",
  data() {
    return {
      page: "Logos",
      logoName: [],
      logoExt: [],
    };
  },
  methods: {
    onClickDownload() {
      var now = new Date();
      var res = now.toISOString().slice(0, 10).replace(/-/g, "");
      var pathToAssets = "/images/";
      const URLS = this.logoName.map((i) => pathToAssets + i + this.logoExt);
      saveToZip("assets-" + this.page + "-" + res + ".zip", URLS);

      function saveToZip(filename, urls) {
        const zip = new JSZip();
        const folder = zip.folder("logos");
        urls.forEach((url) => {
          const blobPromise = fetch(url).then((r) => {
            if (r.status === 200) return r.blob();
            return Promise.reject(new Error(r.statusText));
          });
          const name = url.substring(url.lastIndexOf("/"));
          folder.file(name, blobPromise);
        });

        zip
          .generateAsync({ type: "blob" })
          .then((blob) => saveAs(blob, filename))
          .catch((e) => console.log(e));
      }
    },
  },
  computed: {
    isEmptyName: ({ logoName }) => logoName.length === 0,
    isEmptyExt: ({ logoExt }) => logoExt.length === 0,
  },
};
</script>
<style scoped>
.title-area {
  height: 15%;
}
.elements-area {
  height: 70%;
  /* margin: 4rem 0 2rem; */
  overflow-y: auto;
}
.extension-area {
  border-top: 1px solid lightgrey;
  height: 15%;
}

.submit {
  margin-left: auto;
}
</style>