<template>
  <aside id="navigation" class="menu">
    <h2>Guidelines Anderson</h2>
    <div class="switch">
      <input
        @change="toggleTheme"
        id="checkbox"
        type="checkbox"
        class="switch-checkbox"
      />
      <label for="checkbox" class="switch-label">
        <span>🌙</span>
        <span>☀️</span>
        <div
          class="switch-toggle"
          :class="{ 'switch-toggle-checked': userTheme === 'dark-theme' }"
        ></div>
      </label>
    </div>
    <ul class="main-menu">
      <li class="block item item-logo">
        <router-link to="/">Logo</router-link>
      </li>
      <li class="block item item-typo">
        <router-link to="/typography">Typographie</router-link>
      </li>
      <li class="block item item-colors">
        <router-link to="/colors">Couleurs</router-link>
      </li>
      <li class="block item item-icones">
        <router-link to="/icones">Icones</router-link>
      </li>
      <li class="block item item-graphics">
        <router-link to="/graphics">Éléments graphiques</router-link>
      </li>
      <li class="block item item-modeles">
        <router-link to="/modeles">Modèles</router-link>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: "Sidebar",

  data() {
    return {
      userTheme: "light-theme",
    };
  },
  mounted() {
    const initUserTheme = this.getMediaPreference();
    this.setTheme(initUserTheme);
  },
  methods: {
    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else {
        this.setTheme("light-theme");
      }
    },
    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
  },
};
</script>

<style scoped lang="scss">
aside {
  background-color: #080532;
  height: 100%;
  width: 25%;
  float: left;
}

h2 {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  padding: 20px;
  line-height: 48px;

  @media (min-width: 1200px) {
    font-size: 56px;
    line-height: 62px;
  }
}
ul.main-menu {
  display: block;
  margin: 0 auto;
  max-width: 300px;
}
ul.menu-list li a {
  color: #fff;
}
</style>