<template>
  <div id="content">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Content",
};
</script>

<style>
#content {
  width: 75%;
  float: left;
  border: 0 !important;
}
</style>