import Vue from 'vue'
import VueRouter from 'vue-router'
import Logo from '@/views/Logo.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Logo',
    component: Logo
  },
  {
    path: '/typography',
    name: 'Typography',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Typography.vue')
  },
  {
    path: '/colors',
    name: 'Colors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Colors.vue')
  },
  {
    path: '/icones',
    name: 'Icones',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Icons.vue')
  },
  {
    path: '/graphics',
    name: 'Graphics',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Graphics.vue')
  },
  {
    path: '/modeles',
    name: 'Modeles',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Modeles.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title= `${ process.env.VUE_APP_TITLE } - ${ to.name }`
  next()
  // ...
})


export default router